/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
//import 'gmf/controllers/desktop.scss';
import './sass/vars_desktop.scss';

import angular from 'angular';
import {isEventUsingCtrlKey} from 'ngeo/utils';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController.js';
import gmfPermalinkShareService from 'gmf/permalink/ShareService.js';
import nyonBase from '../nyonmodule.js';
import sitnyonRasterstatisticsModule from '../rasterstatistics/module.js';
import ngeoStreetviewModule from 'ngeo/streetview/module.js';
import sitnFeedbackModule from '../feedback/module.js';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate';
import panels from 'gmfapi/store/panels';
import ngeoMessagePopup from 'ngeo/message/Popup.js';

/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @ngInject
   */
  constructor($scope, $injector, $http, gmfShareService) {
    super($scope, $injector);
    /**
     * @type {angular.$locale} $locale Angular locale
     * @private
     */
    this.$locale_ = $injector.get('$locale');
    // Set manually locale number format.
    $scope.$on('$localeChangeSuccess', (ev) => {
      this.$locale_.NUMBER_FORMATS.DECIMAL_SEP = '.';
      this.$locale_.NUMBER_FORMATS.GROUP_SEP = "'";
    });

    // Allow angular-gettext-tools to collect the strings to translate
    /** @type {angular.gettext.gettextCatalog} */
    const gettextCatalog = $injector.get('gettextCatalog');
    gettextCatalog.getString('Add a theme');
    gettextCatalog.getString('Add a sub theme');
    gettextCatalog.getString('Add a layer');
    gettextCatalog.getString('Local');
    gettextCatalog.getString('Online');

    // Get full entry point
    const pathname = location.pathname;
    const pathnameSplit = pathname.split('/');
    const tag = pathnameSplit[1];
    const urlDomain = location.hostname;
    const substring = pathname.substring(0, 6);

    if (substring == '/theme' || substring == '/') {
      this.url = 'https://' + urlDomain;
    } else {
      this.url = 'https://' + urlDomain + '/' + tag;
    }

    // Get metadata info for copyright
    $http.get(this.url + '/background_metadata').then((resp) => {
      $scope.metadatas = resp.data.metadata;
    });

    // Get links info for external links
    $http.get(this.url + '/tools_external_links').then((resp) => {
      $scope.links = resp.data.link;
    });

    // Show rasterstatistics plugin if user is authorized to
    $scope.$watch(
      () => this.gmfUser.functionalities,
      (newVal) => {
        if (newVal !== null && newVal.authorized_plugins.includes('sitnyon_rasterstatistics')) {
          this.showRasterStatButton = true;
        } else {
          this.showRasterStatButton = false;
        }
      }
    );

    const $timeout = $injector.get('$timeout');

    /**
     * The ngeo ToolActivate manager service.
     * @type {import("ngeo/misc/ToolActivateMgr.js").ToolActivateMgr}
     */
    this.ngeoToolActivateMgr = $injector.get('ngeoToolActivateMgr');

    /**
     * @type {boolean}
     */
    this.drawLidarprofilePanelActive = false;

    /**
     * @type {boolean}
     */
    this.lidarProfileFooterActive = true;

    // Open the 'web-component' lidar panel
    $scope.$watch(
      () => this.drawLidarprofilePanelActive,
      (newVal) => {
        if (newVal) {
          panels.openToolPanel('lidar');
        } else {
          panels.closeToolPanel();
        }
      }
    );

    // Make visible the footer
    panels.getActiveFooterPanel().subscribe({
      next: (panel) => {
        this.lidarProfileFooterActive = panel === 'lidar';
        $timeout(() => {}); // this triggered on DOM click, we call $timeout to force Angular diggest
      },
    });

    const drawLidarprofilePanelActive = new ngeoMiscToolActivate(this, 'drawLidarprofilePanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', drawLidarprofilePanelActive, false);

    // Open ASITVD with current bbox
    const asitvdUrl = $injector.has('asitvdBaseUrl')
      ? $injector.get('asitvdBaseUrl')
      : 'asitvdBaseUrl_not_defined';
    const asitvdProvider = 'bcf3e3d9-212a-4b6c-a8e4-4e0d1f5584cd,442834ef-d0ed-4ff8-978e-903f220aadf7';
    this.openAsitvdWithBbox = () => {
      const extent = this.map.getView().calculateExtent(this.map.getSize());
      for (let i = 0; i < extent.length; i++) {
        extent[i] = extent[i].toFixed(0);
      }
      window.open(asitvdUrl + extent.join('/') + '/?provider=' + asitvdProvider);
    };

    this.gmfShareService_ = gmfShareService;

    this.setShortUrl = () => {
      const pathurl = this.ngeoLocation.getUriString();
      this.gmfShareService_.getShortUrl(pathurl).then((resp) => {
        this.shortLink = resp.data.short_url;
      });
    };

    $scope.loadIframe = false;

    // Share Iframe
    this.setIframeLink = () => {
      $scope.loadIframe = true;
      const path = this.ngeoLocation.getUriString();
      const urlThemeSplit = path.split('/');
      const paramUrl = urlThemeSplit[urlThemeSplit.length - 1];
      this.urliframe = this.url + '/iframe/theme/' + paramUrl;
    };

    $scope.iframeSizes = [
      {
        label: 'small',
        width: 400,
        height: 300,
      },
      {
        label: 'medium',
        width: 600,
        height: 450,
      },
      {
        label: 'big',
        width: 800,
        height: 550,
      },
    ];

    $scope.iframeSize = $scope.iframeSizes[0];

    this.copyToClipboard = () => {
      const copyText = document.getElementById('urlIframe');
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.getElementById('copyButton').innerHTML = 'Lien copié';
      setTimeout(function () {
        document.getElementById('copyButton').innerHTML = 'Copier le lien';
      }, 2000);
    };

    // Print with logo

    $scope.printLogos = [
      {
        label: 'Aucun',
        img: 'blank.png',
      },
      {
        label: 'Coppet',
        img: 'logo_coppet.png',
      },
      {
        label: 'Givrins',
        img: 'logo_givrins.png',
      },
      {
        label: 'Gland',
        img: 'logo_gland.png',
      },
      {
        label: 'Mies',
        img: 'logo_mies.png',
      },
      {
        label: 'Nyon',
        img: 'logo_vdn.png',
      },
      {
        label: 'Perroy',
        img: 'logo_perroy.png',
      },
      {
        label: 'Prangins',
        img: 'logo_prangins.png',
      },
      {
        label: 'Vich',
        img: 'logo_vich.png',
      },
      {
        label: 'Net+ Léman',
        img: 'logo_netplusleman.png',
      },
      {
        label: 'Police Nyon Région',
        img: 'logo_pnr.png',
      },
      {
        label: 'Région de Nyon',
        img: 'logo_region_de_nyon.png',
      },
      {
        label: 'SDIS Gland-Serine',
        img: 'logo_sdis_gs.png',
      },
      {
        label: 'SDIS Nyon-Dôle',
        img: 'logo_sdis_nd.png',
      },
      {
        label: 'SDIS Terre-Sainte',
        img: 'logo_sdis_ts.png',
      },
      {
        label: 'SEIC SA',
        img: 'logo_seic.png',
      },
      {
        label: 'SINyon',
        img: 'logo_sinyon.png',
      },
      {
        label: 'ThermorésÔ Nyon SA',
        img: 'logo_thermoreso_nyon.png',
      },
    ];

    $scope.printLogo = $scope.printLogos[0];

    /**
     * @type {string}
     */
    this.sitnModalUrl;

    /**
     * @type {number}
     */
    this.sitnModalWidth;

    /**
     * @type {number}
     */
    this.sitnModalHeight;

    /**
     * @type {boolean}
     */
    this.sitnModalIsOpen = false;

    /**
     * @type {string}
     */
    this.sitnModalTitle;

    window['sitnExterns'] = {};

    // Bindings to sitnExterns
    window['sitnExterns']['openWindow'] = this.sitnDisplayWindow.bind(this);
  }

  /**
   * @param {string} title Title.
   * @param {string} url Url.
   * @param {number} width Width.
   * @param {number} height Height.
   */
  sitnDisplayWindow(title, url, width, height) {
    this.sitnModalUrl = url;
    this.sitnModalWidth = width;
    this.sitnModalHeight = height;
    this.sitnModalTitle = title;
    this.sitnModalIsOpen = true;
    this.$scope.$apply();
  }

  /**
   * @param {JQuery.Event} event keydown event.
   */
  onKeydown(event) {
    if (event && isEventUsingCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

/**
 * @hidden
 */
const nyonModule = angular.module('Appdesktop', [
  nyonBase.name,
  gmfControllersAbstractDesktopController.name,
  sitnyonRasterstatisticsModule.name,
  ngeoStreetviewModule.name,
  sitnFeedbackModule.name,
  gmfPermalinkShareService.name,
  ngeoMessagePopup.name,
]);

nyonModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
nyonModule.run(
  /* @ngInject */ ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

nyonModule.controller('DesktopController', Controller);

nyonModule.value('asitvdBaseUrl', 'https://viageo.ch/api/shop-select/bbox/');

export default nyonModule;
